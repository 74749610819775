import { API_UPLOAD_PATH } from "../../_emywork/helpers/UIHelper";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}
//TODO remove on production

const PUBLIC_URL =
  process.env.NODE_ENV === "development"
    ? "https://localhost:4000"
    : process.env.REACT_APP_PUBLIC_URL;
export const toAbsoluteUrl = (pathname = "") => PUBLIC_URL + pathname;
export const toAbsoluteApiUrl = (pathname = "") =>
  process.env.REACT_APP_API_URL + pathname;
export const toAbsoluteUploadUrl = (pathname = "") =>
  process.env.REACT_APP_API_URL + "/" + API_UPLOAD_PATH + pathname;
export const toEntityFileNameUrl = (entity, folder) =>
  toAbsoluteUploadUrl(`/${folder}/${entity.id}/${entity.file_name}`);
